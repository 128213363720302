import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';

import { AlertifyService, PermissionService } from '@shared/services';

export const roleGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
  const router = inject(Router);
  const alertify = inject(AlertifyService);
  const permissionService = inject(PermissionService);

  const requiredRoles = route.data.requiredRoles;
  if (permissionService.check(...requiredRoles)) {
    return true;
  }

  alertify.negative('You have no access to see this page');
  return router.createUrlTree(['/login']);
};
